import { fixDateInstance } from '@libs/util';
import { mapOrderStatusFromDto } from '../orders/helpers';
import { CaseCategory, CaseMaterialStatus, } from './models';
const CATEGORY_MAP = {
    CASE: CaseCategory.Case,
    LOCATION: CaseCategory.Location,
    PORTRAIT: CaseCategory.Portrait,
    GROUP: CaseCategory.Group,
    INTRO_OUTRO: CaseCategory.IntroOutro,
    SHOP: CaseCategory.Shop,
    Matterport: CaseCategory.Matterport,
    BEFOREAFTER: CaseCategory.BeforeAfter,
};
const MATERIAL_STATUS_MAP = {
    ACTIVE: CaseMaterialStatus.ACTIVE,
    ARCHIVING: CaseMaterialStatus.ARCHIVING,
    RESTORING: CaseMaterialStatus.RESTORING,
    ARCHIVED: CaseMaterialStatus.ARCHIVED,
};
export function mapCaseCategoryFromDto(dtoCategory) {
    return CATEGORY_MAP[dtoCategory];
}
export function mapMaterialStatusFromDto(dtoStatus) {
    return MATERIAL_STATUS_MAP[dtoStatus];
}
export function mapCaseCategoryToDto(category) {
    return Object.keys(CATEGORY_MAP).find(key => CATEGORY_MAP[key] === category);
}
export function mapFullAddressFromUpdateCaseAddress(dto) {
    let output = '';
    if (dto.street) {
        output += `${dto.street} `;
    }
    if (dto.buildingNumber) {
        output += `${dto.buildingNumber}, `;
    }
    if (dto.buildingFloor) {
        output += `${dto.buildingFloor}, `;
    }
    if (dto.buildingDoor) {
        output += `${dto.buildingDoor}, `;
    }
    if (dto.zip) {
        output += `${dto.zip} `;
    }
    if (dto.city) {
        output += `${dto.city}`;
    }
    return output.trim();
}
export function mapFullAddressFromCaseDetailsAddress(dto) {
    let output = '';
    if (dto.ReferenceAddressStreet) {
        output += `${dto.ReferenceAddressStreet} `;
    }
    if (dto.ReferenceAddressHouseNumber) {
        output += `${dto.ReferenceAddressHouseNumber}, `;
    }
    if (dto.ReferenceAddressFloor) {
        output += `${dto.ReferenceAddressFloor}, `;
    }
    if (dto.ReferenceAddressDoor) {
        output += `${dto.ReferenceAddressDoor}, `;
    }
    if (dto.ReferenceAddressState) {
        output += `${dto.ReferenceAddressState}, `;
    }
    if (dto.ReferenceAddressCityArea) {
        output += `${dto.ReferenceAddressCityArea}, `;
    }
    if (dto.ReferenceAddressZip) {
        output += `${dto.ReferenceAddressZip} `;
    }
    if (dto.ReferenceAddressCity) {
        output += `${dto.ReferenceAddressCity}`;
    }
    return output.trim();
}
export function mapAddressFromCaseDetailsAddressDto(dto) {
    const { ReferenceAddressStreet: street, ReferenceAddressHouseNumber: houseNumber, ReferenceAddressFloor: floor, ReferenceAddressDoor: appartmentNumber, ReferenceAddressZip: postNumber, ReferenceAddressCity: city, ReferenceAddressState: area, } = dto;
    return street && houseNumber && postNumber && city
        ? {
            id: '<internal>',
            street,
            houseNumber,
            floor,
            appartmentNumber,
            postNumber,
            city,
            area,
            full: mapFullAddressFromCaseDetailsAddress(dto),
        }
        : undefined;
}
export function mapDatahubCaseFromDto({ id, reference, axCustomerId, modifiedDate, branchId, branchName, address, ordersInfo, materialInfo, contactPersons, propertyInfo, }) {
    var _a, _b;
    return {
        id,
        reference,
        updatedAt: modifiedDate ? new Date(fixDateInstance(modifiedDate)) : undefined,
        deliveryDate: (ordersInfo === null || ordersInfo === void 0 ? void 0 : ordersInfo.latestOrderRelatedChangeTimestamp) != null
            ? new Date(fixDateInstance(ordersInfo === null || ordersInfo === void 0 ? void 0 : ordersInfo.latestOrderRelatedChangeTimestamp))
            : null,
        departmentId: branchId,
        departmentName: branchName,
        address: ((_a = address === null || address === void 0 ? void 0 : address.addressFull) === null || _a === void 0 ? void 0 : _a.trim()) || '',
        addressObj: address && {
            full: (_b = address.addressFull) === null || _b === void 0 ? void 0 : _b.trim(),
            street: address.street,
            houseNumber: address.buildingNumber,
            city: address.city,
            postNumber: address.zip,
        },
        orderStatus: ordersInfo === null || ordersInfo === void 0 ? void 0 : ordersInfo.orderStatus,
        hasMaterials: !!(materialInfo === null || materialInfo === void 0 ? void 0 : materialInfo.hasMaterials),
        isArchived: (materialInfo === null || materialInfo === void 0 ? void 0 : materialInfo.materialsState) === 'ARCHIVED' || (materialInfo === null || materialInfo === void 0 ? void 0 : materialInfo.materialsState) === 'ARCHIVING',
        clientId: axCustomerId,
        materialStatus: materialInfo === null || materialInfo === void 0 ? void 0 : materialInfo.materialsState,
        categories: materialInfo === null || materialInfo === void 0 ? void 0 : materialInfo.deliveredProductCategories,
        contactPersons: (contactPersons === null || contactPersons === void 0 ? void 0 : contactPersons.sort((prev, next) => prev.priority - next.priority).map(({ contactType, name, contactInfo }) => ({
            type: contactType,
            name,
            email: contactInfo === null || contactInfo === void 0 ? void 0 : contactInfo.email,
            phone: contactInfo === null || contactInfo === void 0 ? void 0 : contactInfo.phoneMobile,
            phoneAlt: (contactInfo === null || contactInfo === void 0 ? void 0 : contactInfo.phoneWork) || (contactInfo === null || contactInfo === void 0 ? void 0 : contactInfo.phonePrivate),
        }))) || [
            {
                type: '',
                name: '',
                phone: '',
                phoneAlt: '',
            },
        ],
        property: {
            type: propertyInfo === null || propertyInfo === void 0 ? void 0 : propertyInfo.propertyType,
            livingArea: propertyInfo === null || propertyInfo === void 0 ? void 0 : propertyInfo.registeredArea,
            basementArea: propertyInfo === null || propertyInfo === void 0 ? void 0 : propertyInfo.basementArea,
            floors: propertyInfo === null || propertyInfo === void 0 ? void 0 : propertyInfo.storeys,
        },
    };
}
export function mapCaseFiltersFromDto({ types, branches, statuses }) {
    return {
        categories: (types === null || types === void 0 ? void 0 : types.filter(type => !!type).map(mapCaseCategoryFromDto)) || [],
        statuses: (statuses === null || statuses === void 0 ? void 0 : statuses.filter(status => !!status).map(mapOrderStatusFromDto)) || [],
        departments: (branches === null || branches === void 0 ? void 0 : branches.filter(branch => !!branch).map(({ id, name }) => ({ id, name }))) || [],
    };
}
export function mapCaseOrderByParamToDto(orderBy) {
    return (orderBy &&
        {
            ModifiedDate: 'ModifiedDate',
            Reference: 'Reference',
            Address: 'Address',
            Score: 'Score',
        }[orderBy]);
}
export function mapCaseDetailsRequestParamsToDto({ case: caseObj, address, contactPersons, property, branchId, }) {
    var _a, _b, _c, _d;
    return {
        branchId,
        reference: caseObj.reference,
        orderContacts: (contactPersons === null || contactPersons === void 0 ? void 0 : contactPersons.length)
            ? contactPersons === null || contactPersons === void 0 ? void 0 : contactPersons.map(({ name, phone, email, type }) => ({
                name,
                phone,
                email,
                contactType: type,
            }))
            : [],
        ...(address && {
            address: {
                street: address.street,
                buildingNumber: address.houseNumber,
                buildingFloor: address.floor,
                buildingDoor: address.appartmentNumber,
                cityArea: address.area,
                city: address.city,
                zip: address.postNumber,
            },
        }),
        ...((property === null || property === void 0 ? void 0 : property.type) && {
            propertyInfo: {
                propertyType: (_a = property.type) !== null && _a !== void 0 ? _a : '',
                basementArea: (_b = property.basementArea) !== null && _b !== void 0 ? _b : 0,
                registeredArea: (_c = property.livingArea) !== null && _c !== void 0 ? _c : 0,
                totalStoreys: (_d = property.floors) !== null && _d !== void 0 ? _d : 0,
            },
        }),
    };
}
