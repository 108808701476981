export function mapCloudScannerLinkFromDto({ linkType, url }) {
    return {
        type: linkType,
        url,
    };
}
export function mapSimplifiedVisualMaterialFromDto(materialDto) {
    var _a, _b;
    return {
        ...materialDto,
        largestSource: materialDto.originalLargestMaterialUrl,
        orderlineId: materialDto.orderLineId,
        batchId: (_a = materialDto.batchId) === null || _a === void 0 ? void 0 : _a.toString(),
        updatedDate: materialDto.originalMaterialAsset.updatedDate
            ? new Date(materialDto.originalMaterialAsset.updatedDate)
            : undefined,
        isSelected: false,
        isIFPMaterial: false,
        productCategory: materialDto.productCategory,
        type: materialDto.originalMaterialAssetType,
        before: materialDto.materialBefore,
        after: materialDto.materialAfter,
        vendorOrderLineId: materialDto.vendorOrderLineId || undefined,
        cloudScannerLinks: ((_b = materialDto.cloudScannerLinks) === null || _b === void 0 ? void 0 : _b.map(mapCloudScannerLinkFromDto)) || [],
    };
}
export function mapLinkOrDocumentMaterialFromDto(materialDto) {
    const { id, description, type, url } = materialDto;
    return {
        id,
        isActive: true,
        isSelected: false,
        name: description,
        url,
        type,
        _dto: materialDto,
    };
}
export function mapLinkOrDocumentMaterialToDto(material) {
    const { id, name, type, url } = material;
    return {
        id: id,
        url,
        description: name,
        type,
    };
}
